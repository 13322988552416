import React from "react";
import BlogList from "./BlogList";
import "./BlogLanding.css"; // Import the CSS file for styling

const BlogLanding = () => {
  return (
    <div className="blog-landing">
      <h1 className="blog-landing__title">
        Insights & Stories to Fuel Your Growth
      </h1>

      <p className="blog-landing__intro">
        Welcome to the Innonative Solutions blog—a space where we share
        insights, strategies, and stories to help you connect with your
        audience, grow your business, and thrive in the digital world. Dive into
        our latest posts and discover how you can turn your vision into a
        narrative that resonates.
      </p>
      <BlogList />
    </div>
  );
};

export default BlogLanding;

import React from "react";
import "./AboutSnippet.css";

const AboutSnippet = () => {
  return (
    <section className="about-snippet">
      <h2>
        About Innonative Solutions LLC: Crafting Digital Experiences that Matter
      </h2>
      <p>
        Founded by Charles Werk, Innonative Solutions LLC is more than just a
        tech company—we're your partner in bringing your vision to life.
        Specializing in web and mobile app development, software engineering,
        and consulting, we help businesses and communities create digital
        experiences that resonate. Our mission is to empower you with the tools
        and guidance you need to tell your story, connect with your audience,
        and thrive in the digital age.
      </p>
    </section>
  );
};

export default AboutSnippet;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { fetchPosts } from "../services/contentfulService";
import "./BlogList.css"; // Import the CSS file for styling

const BlogList = () => {
  const [posts, setPosts] = useState([]);
  const [view, setView] = useState("grid"); // Default view set to grid

  useEffect(() => {
    const getPosts = async () => {
      const data = await fetchPosts();
      setPosts(data);
    };

    getPosts();
  }, []);

  const formatDate = (timestamp) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(timestamp).toLocaleDateString(undefined, options);
  };

  return (
    <div>
      <div className="blog-controls">
        <button onClick={() => setView(view === "grid" ? "list" : "grid")}>
          {view === "grid" ? "Detail" : "Grid"} View
        </button>
      </div>
      <h2 className="latest-articles">Latest Articles</h2>
      <div className={`blog-posts ${view}`}>
        {posts.map((post) => (
          <div key={post.fields.slug} className="blog-post-item">
            {view === "grid" ? (
              <div className="blog-post-card">
                {post.fields.featuredImage && (
                  <Link to={`/blog/${post.fields.slug}`}>
                    <img
                      src={post.fields.featuredImage.fields.file.url}
                      alt={post.fields.title}
                      className="blog-post-image"
                    />
                  </Link>
                )}
                <div className="blog-post-content">
                  <Link to={`/blog/${post.fields.slug}`}>
                    <h2>{post.fields.title}</h2>
                  </Link>
                  {/* <p>{post.fields.excerpt}</p> */}
                  <div className="blog-post-meta">
                    <div className="blog-post-author">{post.fields.author}</div>
                    <div className="blog-post-date">
                      <em>{formatDate(post.fields.date)}</em>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="blog-post-list">
                {post.fields.featuredImage && (
                  <img
                    src={post.fields.featuredImage.fields.file.url}
                    alt={post.fields.title}
                    className="blog-post-list-image"
                  />
                )}
                <div className="blog-post-list-content">
                  <Link to={`/blog/${post.fields.slug}`}>
                    <h2>{post.fields.title}</h2>
                  </Link>
                  <p>{post.fields.excerpt}</p>
                  <div className="blog-post-tags">
                    <em>
                      Tags: {post.fields.tags && post.fields.tags.join(", ")}
                    </em>
                  </div>
                  <div className="blog-post-meta">
                    <div className="blog-post-author">{post.fields.author}</div>
                    <div className="blog-post-date">
                      <em>{formatDate(post.fields.date)}</em>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogList;

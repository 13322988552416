import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { fetchPostBySlug } from "../services/contentfulService";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import "./BlogPostPage.css";

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { file, title } = node.data.target.fields;
      return <img src={file.url} alt={title} />;
    },
    [INLINES.HYPERLINK]: (node) => {
      const { uri } = node.data;
      return (
        <a href={uri} target="_blank" rel="noopener noreferrer">
          {node.content[0].value}
        </a>
      );
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p>{children}</p>;
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      return <h1>{children}</h1>;
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2>{children}</h2>;
    },
    // Add more blocks as needed
  },
};

const BlogPostPage = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const getPost = async () => {
      const data = await fetchPostBySlug(slug);
      setPost(data);
    };

    getPost();
  }, [slug]);

  if (!post) return <div>Loading...</div>;

  const {
    seoTitle,
    metaDescription,
    canonicalUrl,
    title,
    date,
    category,
    content,
    featuredImage,
  } = post.fields;

  return (
    <>
      <Helmet>
        <title>{seoTitle || title}</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div className="blog-post">
        <h1>{title}</h1>
        <p>
          <em>
            {new Date(date).toDateString()} - {category}
          </em>
        </p>
        {featuredImage && (
          <img src={featuredImage.fields.file.url} alt={title} />
        )}
        <div>{documentToReactComponents(content, options)}</div>
      </div>
    </>
  );
};

export default BlogPostPage;

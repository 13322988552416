import { createClient } from "contentful";

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

export const fetchPosts = async () => {
  const response = await client.getEntries({
    content_type: "blogPost", // Assuming 'blogPost' is your content type ID
    order: "-fields.date", // Order by date descending
  });

  return response.items;
};

// Fetch a single post by slug
export const fetchPostBySlug = async (slug) => {
  const response = await client.getEntries({
    content_type: "blogPost",
    "fields.slug": slug,
  });
  return response.items[0];
};

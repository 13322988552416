import React from "react";
import "./Hero.css"; // Ensure Hero.css contains the styles below

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-content">
        <h1>Empower Your Brand's Story with Innovative Technology</h1>
        <h2>Create Meaningful Connections through Custom Digital Solutions</h2>
        <p>
          At Innonative Solutions, we do more than just build websites and
          apps—we help you craft a digital presence that feels like home to your
          customers, where your story comes to life. Let’s work together to turn
          your vision into an experience that resonates and drives your business
          forward.
        </p>
        <div className="hero-buttons">
          <a
            href="/contact"
            className="hero-button"
            aria-label="Learn more about our services"
          >
            Start Your Journey with Us
          </a>
        </div>
      </div>
      {/* <div className="hero-image">
        <img src="/assets/NoBGCropped.png" alt="Business Solutions" />
      </div> */}
    </div>
  );
};

export default Hero;
